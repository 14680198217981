<template>
  <page-container
      ref="pageContainer"
      class="goods-page-container"
      :class="{'is-column-model': viewModel === 'column'}"
      :scroll-top.sync="scrollTop"
  >
    <van-icon class="back-icon" name="arrow-left" @click.stop="$router.back()"/>

    <!-- 固钉 -->
    <affix-item
        style="position: relative;z-index: 10"
        :target="() => $refs.pageContainer.$el"
        @change="flag => this.affixIsTop = flag"
    >
      <!-- 分类搜索 -->
      <div class="goods-search-container" :class="{ affixIsTop }">
        <van-search
            v-model.trim="searchName"
            shape="round"
            show-action
            placeholder="请输入搜索关键词"
            @search="handleNameSearch"
        >
          <template #left v-if="affixIsTop">
            <div style="padding: 0 .03rem 0 0;font-size: .06rem;">
              <van-icon name="arrow-left" @click.stop="$router.back()"/>
            </div>
          </template>
          <template #action>
            <div class="search-btn" @click="handleNameSearch">搜索</div>
          </template>
        </van-search>
        <ul class="goods-mode-container">
          <li
              v-for="({ name, code, order }, index) of searchModelList"
              :class="{'search-model-checked': searchModelCheck.code === code}"
              :key="name"
              @click="handleSearchModelClick(searchModelList[index])"
          >
            <span class="search-name">{{ name }}</span>
            <span class="search-order" v-if="order !== null"><van-icon
                :name="order === true ? 'ascending' : 'descending'"
            /></span>
          </li>
          <li>
            <van-icon
                name="apps-o"
                @click="() => {
                this.viewModel = viewModel === 'row' ? 'column' : 'row'
              }"
            />
          </li>
        </ul>

      </div>
    </affix-item>
    <!-- 下拉刷新 -->
    <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        style="position: relative;z-index: 1"
    >
      <template #loading>
        {{ pagination.pageNum < 2 ? '' : '加载中...' }}
      </template>

      <!-- 更多列表加载 -->
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <goods-info
            v-for="item in dataList"
            :view-model="viewModel"
            :key="item.id"
            @click="handleGoodsClick(item)"
            :value="{
              goodsImgSrc: item.thumbImageUrl,
              goodsName: item.name,
              goodsDesc: '',
              goodsPrice: item.goodsPrice,
            }"
        >
          <!--                    <template #price-right>-->
          <!--                      ×123-->
          <!--                    </template>-->
        </goods-info>
      </van-list>
    </van-pull-refresh>
  </page-container>
</template>

<script>
import { getPageAllNormalGoods } from '@/api/normalGoods';
import { mapState } from 'vuex';
import GoodsInfo from '@/components/GoodsInfo';
import AffixItem from '@/components/Affix';

export default {
  name: 'normalGoodsList',
  components: { AffixItem, GoodsInfo },
  data() {
    return {
      viewModel: 'row', //column
      affixIsTop: false,

      // asc desc
      searchModelList: [
        { name: '综合', code: '综合', order: null },
        { name: '销量', code: '销量', order: true },
        { name: '价格', code: '价格', order: false },
      ],
      searchName: '', // 商品名input
      name: '', // 商品名称搜索字段
      searchModelCheck: {},

      scrollTop: 0,
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.normalGoodsDetail.refreshing,
    }),
    searchData() {
      let { searchModelCheck, searchModelList } = this;
      let [def] = searchModelList;
      let { code: defCode, order: defOrder } = def;
      let { code = defCode, order = defOrder } = searchModelCheck;
      let orderKey = {};
      if (order !== null) {
        orderKey = {
          orderKey: order ? 'asc' : 'desc'
        };
      }
      return { code, ...orderKey };
    },
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.name = this.searchName = this.$route.query.searchGoodsName || '';
      this.searchModelCheck = this.searchModelList[0];
      this.scrollTop = 0;
      this.$store.commit('normalGoodsDetail/setRefreshing', false);
      this.onRefresh();
    }, { immediate: true });

  },
  mounted() {
  },
  activated() {
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },
  methods: {
    onLoad() {
      this.pagination.pageNum++;
      let {
        name,
        searchData,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getPageAllNormalGoods({
        name,
        classifyId: this.$route.query.classifyId || '',
        ...searchData,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },

    handleSearchModelClick(obj) {
      if (this.loading) return;
      let { name, order } = this.searchModelCheck;
      if (obj.name === name && order !== null)
        this.searchModelCheck.order = !this.searchModelCheck.order;
      else
        this.searchModelCheck = obj;

      this.onRefresh();
    },
    handleNameSearch() {
      this.name = this.searchName;
      this.onRefresh();
    },
    handleGoodsClick(obj) {
      this.$router.push({
        path: '/goodsDetail',
        query: {
          normalGoodsId: obj.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@bg-color: #f6f6f6;

.back-icon {
  position: fixed;
  z-index: 2;
  top: 12px;
  left: 12px;
  box-sizing: border-box;
  padding: 6px;
  font-size: 24px;
  color: #000;
  background-color: rgba(255, 255, 255, .8);
  border-radius: 100%;
  border: 1px solid #dedede;
}

.goods-page-container {
  padding-top: 84px;
  background: url("../../img/categories/topBackground.png") top center no-repeat #fff;
  background-size: 100% auto;

  .goods-search-container {
    background-color: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    overflow: hidden;
    transition: border-radius 1s;

    &.affixIsTop {
      border-radius: 0;
    }
  }

  .goods-mode-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 26px;
    height: 40px;

    & > li {
      display: flex;
      align-items: center;
      font-size: 16px;

      &.search-model-checked {
        color: @primary-color;
      }

      .search-name {
        line-height: 1;
      }

      .search-order {
        margin-left: 6px;
      }

    }
  }

  .van-icon.van-icon-apps-o {
    font-size: 26px;
    color: #999;
  }


  .van-pull-refresh {
    background-color: #fff;
  }

  .van-list {
    padding: 10px 10px 0 10px;
  }


  &.is-column-model {
    background-color: @bg-color;

    .van-icon.van-icon-apps-o {
      color: @primary-color;
    }

    .van-pull-refresh {
      background-color: @bg-color;
    }

    .van-list {
      padding: 10px 10px 0 10px;
    }

  }

}
</style>
