var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',{ref:"pageContainer",staticClass:"goods-page-container",class:{'is-column-model': _vm.viewModel === 'column'},attrs:{"scroll-top":_vm.scrollTop},on:{"update:scrollTop":function($event){_vm.scrollTop=$event},"update:scroll-top":function($event){_vm.scrollTop=$event}}},[_c('van-icon',{staticClass:"back-icon",attrs:{"name":"arrow-left"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.back()}}}),_c('affix-item',{staticStyle:{"position":"relative","z-index":"10"},attrs:{"target":function () { return _vm.$refs.pageContainer.$el; }},on:{"change":function (flag) { return this$1.affixIsTop = flag; }}},[_c('div',{staticClass:"goods-search-container",class:{ affixIsTop: _vm.affixIsTop }},[_c('van-search',{attrs:{"shape":"round","show-action":"","placeholder":"请输入搜索关键词"},on:{"search":_vm.handleNameSearch},scopedSlots:_vm._u([(_vm.affixIsTop)?{key:"left",fn:function(){return [_c('div',{staticStyle:{"padding":"0 .03rem 0 0","font-size":".06rem"}},[_c('van-icon',{attrs:{"name":"arrow-left"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.back()}}})],1)]},proxy:true}:null,{key:"action",fn:function(){return [_c('div',{staticClass:"search-btn",on:{"click":_vm.handleNameSearch}},[_vm._v("搜索")])]},proxy:true}],null,true),model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchName"}}),_c('ul',{staticClass:"goods-mode-container"},[_vm._l((_vm.searchModelList),function(ref,index){
var name = ref.name;
var code = ref.code;
var order = ref.order;
return _c('li',{key:name,class:{'search-model-checked': _vm.searchModelCheck.code === code},on:{"click":function($event){return _vm.handleSearchModelClick(_vm.searchModelList[index])}}},[_c('span',{staticClass:"search-name"},[_vm._v(_vm._s(name))]),(order !== null)?_c('span',{staticClass:"search-order"},[_c('van-icon',{attrs:{"name":order === true ? 'ascending' : 'descending'}})],1):_vm._e()])}),_c('li',[_c('van-icon',{attrs:{"name":"apps-o"},on:{"click":function () {
              this$1.viewModel = _vm.viewModel === 'row' ? 'column' : 'row'
            }}})],1)],2)],1)]),_c('van-pull-refresh',{staticStyle:{"position":"relative","z-index":"1"},on:{"refresh":_vm.onRefresh},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.pagination.pageNum < 2 ? '' : '加载中...')+" ")]},proxy:true}]),model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.dataList),function(item){return _c('goods-info',{key:item.id,attrs:{"view-model":_vm.viewModel,"value":{
            goodsImgSrc: item.thumbImageUrl,
            goodsName: item.name,
            goodsDesc: '',
            goodsPrice: item.goodsPrice,
          }},on:{"click":function($event){return _vm.handleGoodsClick(item)}}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }